import React from 'react'
import {
  Card,
  CardContainer,
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListItem,
  ListWrapper,
  TitleSectionH3,
  Wrapper
} from './HowToFind.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const HowToFind = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <CardContainer>
            <InfoContainer>
              <Title.H2>
                Как найти и выбрать психотерапевта КПТ онлайн?
              </Title.H2>
            </InfoContainer>

            <Card>
              <TitleWrapper>
                <TitleSectionH3>1. Уточните образование</TitleSectionH3>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Для работы в подходе КПТ необходимо:
                </Text.Large>
                <ListWrapper>
                  <ListItem>
                    <Text.Large semiBold>
                      базовое психологическое образование;
                    </Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>
                      повышение квалификации по КПТ.
                    </Text.Large>
                  </ListItem>
                </ListWrapper>
              </DescriptionWrapper>

              <TitleWrapper>
                <TitleSectionH3>2. Узнайте направление работы</TitleSectionH3>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Большинство КПТ терапевтов онлайн работают с депрессией,
                  тревогой, прокрастинацией, однако не у всех есть специфичные
                  знания для лечения депрессии, работы с отношениями или СДВГ
                </Text.Large>
              </DescriptionWrapper>
            </Card>
          </CardContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
