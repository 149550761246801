import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { AutoThoughts } from '../organisms/Kpt/AutoThoughts'
import { CommentsSection } from '../organisms/CommentsSection'
import { DescriptionKpt } from '../organisms/Kpt/DescriptionKpt'
import { ExamplesSection } from '../organisms/Kpt/ExamplesSection'
import { ExtremeBeliefs } from '../organisms/Kpt/ExtremeBeliefs'
import { HowItSection } from '../organisms/HowItSection'
import { HowToFind } from '../organisms/Kpt/HowToFind'
import { KptComponents } from '../organisms/Kpt/KptComponents'
import { KptHeroBlock } from '../organisms/Kpt/KptHeroBlock'
import { KptInYoutalk } from '../organisms/Kpt/KptInYoutalk'
import { ProductMicromarkupComponent } from '../atoms/SEO/Micromarkup/Product'
import { PsychologistsWithKpt } from '../organisms/Kpt/PsychologistsWithKpt'
import { QueriesKpt } from '../organisms/Kpt/QueriesKpt'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { SessionsOnline } from '../organisms/Kpt/SessionsOnline'
import { SituationSection } from '../organisms/Kpt/SituationSection'
import { WhatIsKpt } from '../organisms/Kpt/WhatIsKpt'
import { WhoIsSuitable } from '../organisms/Kpt/WhoIsSuitable'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { kptCommentsData } from '../organisms/Kpt/kptCommentsData'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: var(--height);
  background: #dfebf9;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const KptPage = ({ data }) => (
  <App>
    <SEO
      description="Психологи специалисты по когнитивно-поведенческой терапии онлайн ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Психолог КПТ онлайн — психотерапевт по когнитивно-поведенческой терапии онлайн | YouTalk"
    >
      <ProductMicromarkupComponent name="Как справиться с тревожностью? Психологическая помощь при тревоге" />
    </SEO>
    <Page>
      <Header />
      <Main>
        <KptHeroBlock />
        <WhatIsKpt />
        <PsychologistsWithKpt data={pick(['psychologists'], data)} />
        <KptComponents />
        <ExamplesSection />
        <AutoThoughts />
        <SituationSection />
        <ExtremeBeliefs />
        <DescriptionKpt />
        <WhoIsSuitable />
        <QueriesKpt />
        <HowToFind />
        <KptInYoutalk />
        <CommentsSection commentsCard={kptCommentsData} />
        <SessionsOnline />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default KptPage

export const query = graphql`
  query KptPage {
    psychologists: allPsychologistsData(
      filter: {
        nearest_slot: { ne: null }
        visibility: { eq: "OPEN" }
        approaches: {
          elemMatch: { name: { in: "Когнитивно-поведенческая терапия" } }
        }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
