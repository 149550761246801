import React from 'react'
import {
  BlueCard,
  Br,
  ButtonWrapper,
  CardContainer,
  Content,
  DescriptionWrapper,
  InfoContainer,
  TitleSectionH3,
  Wrapper
} from './DescriptionKpt.styles'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const DescriptionKpt = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <CardContainer>
            <InfoContainer>
              <TitleWrapper>
                <TitleSectionH3>
                  С точки зрения когнитивно поведенческой терапии, наши чувства
                  и действия зависят от мыслей.
                </TitleSectionH3>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Чтобы жизнь поменялась, нужно проверять,
                  <Br /> не искажают ли наши мысли действительность.
                </Text.Large>
                <Text.Large semiBold>
                  Сначала это приходится делать намеренно, но через некоторое
                  время навык становится автоматическим.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>

            <BlueCard>
              <TitleWrapper>
                <TitleSectionH3>
                  Важный компонент КПТ — это ее прозрачность
                </TitleSectionH3>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Онлайн КПТ психолог объяснит, как работает психика и чем
                  помогут рекомендованные техники.
                </Text.Large>
                <Text.Large semiBold>
                  Цель онлайн КПТ терапии — решить ваш запрос и дать навыки,
                  которыми вы сможете применять самостоятельно после завершения
                  психотерапевтической работы.
                </Text.Large>
              </DescriptionWrapper>
            </BlueCard>
          </CardContainer>
          <ButtonWrapper>
            <Button.NewPrimary href="/wizard/" size="large" type="link">
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
