import React from 'react'
import {
  AutoThoughtsImage,
  Br,
  ButtonWrapper,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TitleSection,
  Wrapper
} from './AutoThoughts.styles'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const AutoThoughts = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <TitleSection>
                Базовые убеждения
                <Br /> проявляются
                <Br /> в автоматических мыслях
              </TitleSection>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                В нашей голове будто всплывают уведомления с сообщениями:
              </Text.Large>
              <Text.Large semiBold>
                Мы настолько привыкли к автоматическим мыслям, что уже не
                замечаем их. А они продолжают влиять на наши эмоции и поведение.
              </Text.Large>
            </DescriptionWrapper>
            <ButtonWrapper>
              <Button.NewPrimary href="/wizard/" size="large" type="link">
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonWrapper>
          </InfoContainer>
          <ImageWrapper>
            <AutoThoughtsImage alt="Базовые убеждения проявляются в автоматических мыслях" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
