export const kptCommentsData = [
  {
    desc: 'Доверяю сервису уже более 4 лет, было несколько специалистов.',
    name: 'Ирина',
    age: '28 лет',
    title: 'Доверяю сервису уже более 4 лет',
    img: 'person1.svg'
  },
  {
    desc: 'Сервис YouTalk оказался очень удобным. Я заполнила анкету и быстро получила рекомендации. Выбранный психолог был профессионален и внимателен, я чувствовала себя комфортно.',
    name: 'Полина',
    age: '36 лет',
    title: 'Психолог был профессионален и внимателен',
    img: 'person7.svg'
  },
  {
    desc: 'Я долго искала онлайн-консультацию с психологом и наконец нашла. Платформа очень понятная, а предварительная консультация для выбора специалиста просто отличная. Уверена, что здесь каждый сможет найти помощь.',
    name: 'Злата',
    age: '21 год',
    title: 'Уверена, что здесь каждый сможет найти помощь.',
    img: 'person0.svg'
  },
  {
    desc: 'Давно занимаюсь с психологом Еленой на этой платформе. Нравится удобная запись и простота с оплатой. Спасибо программистам за удобный личный кабинет!',
    name: 'Арина',
    age: '21 год',
    title: 'Удобная запись и простота с оплатой',
    img: 'person8.svg'
  },
  {
    desc: 'Я очень довольна работой специалиста! Мне всегда трудно открываться новым людям, но психолог быстро смогла установить контакт со мной и я почувствовала значительное улучшение уже после нескольких встреч. Горжусь собой и благодарю Анну 🙂',
    name: 'Карина',
    age: '49 лет',
    title: 'Я почувствовала значительное улучшение',
    img: 'person9.svg'
  },
  {
    desc: 'Как студентка, я искала доступного по цене психолога для помощи в борьбе со стрессом, связанным с учебой. Консультант по подбору помог мне подобрать специалиста по доступной цене. Я была приятно удивлена качеством консультаций – мой психолог оказалась очень внимательной и профессиональной. Рекомендую всем 💕',
    name: 'Екатерина',
    age: '21 год',
    title: 'Как студентка, я искала доступного по цене психолога',
    img: 'person6.svg'
  }
]
