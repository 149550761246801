import styled from 'styled-components'
import { Button } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background-color: #fff;
`

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    margin-bottom: 48px;
    align-items: flex-start;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const TitleSection = styled.div`
  color: #333333;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const TitleSectionH3 = styled.div`
  color: #333333;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const BlueCard = styled.div`
  max-width: 588px;
  background-color: #dfebf9;
  padding: 24px;
  border-radius: 24px;

  @media (max-width: ${size.lg}) {
    max-width: 468px;
  }
  @media (max-width: ${size.md}) {
    max-width: 100%;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: none;

  @media (max-width: ${size.sm}) {
    display: block;

    ${Button.NewPrimary} {
      width: 100%;
    }
  }
`

export const Br = styled.br`
  @media (max-width: ${size.md}) {
    display: none;
  }
`

export const InfoContainer = styled.div`
  max-width: 588px;

  @media (max-width: ${size.lg}) {
    max-width: 468px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }
`
