import React from 'react'
import loveIcon from '../../../../static/img/Kpt/love-icon.svg'
import messageIcon from '../../../../static/img/Kpt/message-icon.svg'
import studyIcon from '../../../../static/img/Kpt/study-icon.svg'
import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './KptInYoutalk.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const KptInYoutalk = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2>
                Когнитивно-поведенческая терапия онлайн в YouTalk
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold>
                Мы собрали команду проверенных КПТ психотерапевтов, которые
                помогут вам найти пути к счастью и удовлетворению.
              </Text.Large>
              <Text.Large semiBold>
                Все психологи YouTalk проходят тщательный отбор: проверку
                образования, личной терапии, супервизии и умения работать с
                уязвимыми группами.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={loveIcon} />
              <Text.Large semiBold>
                Все психологи КПТ в YouTalk имеют необходимое образование и
                опыт, регулярно повышают свою квалификацию
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={studyIcon} />
              <Text.Large semiBold>
                Все наши КПТ-специалисты имеют от года опыта
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={messageIcon} />
              <Text.Large semiBold>
                95% наших клиентов находят своего психолога с первой попытки
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
